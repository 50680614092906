li::marker {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff3535;
  opacity: 1;
  width: 43px !important;
  height: 43px !important;
}

.icon_styles {
  width: 52px;
  height: 52px;
}

.message_button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff3535;
  border-radius: 10px;
  opacity: 1;
  height: 51px;
  width: 188px;
  text-align: center;
  font: normal normal bold 18px/26px Roboto;
  letter-spacing: 0px;
  color: #ff3535;
}

.contactUs_style {
  width: 511px;
  height: 200px;

  opacity: 1;
}

.contactUs_topgrid {
  margin-top: 0px !important;
  margin-bottom: 70px;
  align-items: center;
  background: transparent
    linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%) 0% 0%
    no-repeat padding-box;
  height: 200px;
}

.li_numbering {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #ff3535;
  color: #ff3535;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  margin-right: 0.5em;
  box-sizing: content-box;
  padding: 5px;
  font-weight: bold;
  line-height: 35px;
}

.li_overall {
  margin-bottom: 30px;
  color: black;
  display: flex;
  align-items: center;
}

.ourRolescontent {
  text-align: left;
  letter-spacing: 0.11px;
  color: #000000;
}

.values_card {
  text-align: center;
  padding-top: 20px;
  opacity: 0.6;
}

.required-asterisk {
  color: red;
  margin-left: 5px;
}

.input-container {
  display: flex;
  align-items: center;
}

.success-message {
  color: green;
  margin-top: 15px;
  margin-bottom: 5px;
}
.our-team-container {
  padding-right: 20px;
  padding-left: 20px;
}
.our-team-position{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 29px;
  letter-spacing: -0.36px
}
/* .team-popup-scroll{
  height: 250px;
}  */

/* mobile devices */
@media only screen and (max-width: 767px) {
  .container-aboutus-contactus {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .contactus-header-img {
    width: 100%;
}
.icon_styles {
  width: 35px;
  height: 35px;
}
.contactus-middle-container{
margin: 30px 0px 0px ;
}
.contactus-middle-title{
margin-bottom: 25px !important;
}
.contactus-middle-title1{
  margin-top: 5px !important;
  margin-bottom: 18px !important;

  }
  .contactus-btn-container{
    display: flex;
    justify-content: center;
  }
  
.contactus-pricing-btn {
  border-radius: 10px !important;
  background: #fff !important;
  color: #ff3535 !important;
  opacity: 1;
  border-color: #ff3535 !important;
  text-transform: unset !important;
  /* width: 195px; */
  margin:10px 0px 24px !important;
  width: 188px ; 
  height: 50px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.contact-form-alert-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;

  background-color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px;
}
.contact-info{
  display: flex;
  align-items: center;
  color: black;
  margin-top: 10px !important;
}
  .formStyles {
    width: 100% !important;
    height: 50px;
    border: 1px solid #d8d8d8;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-left: 0px !important;
    margin-bottom: 11px !important;
    padding: 15px 0px 15px 17px;
  }
  .err-input {
    margin-left: 0px !important;
    border: 1px solid #ff3535 !important;
    box-shadow: 0px 2px 10px #00000029 !important;
  }
  .error-message {
    float: right;
    margin-right: 0px !important;
    font-size: 12px !important;
    color: #FF3535 !important;
}
  .MuiInputBase-root.MuiInput-root:after {
    border-bottom: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
    border-color: #d8d8d8;
  }
  .MuiInputBase-root.MuiInput-root:before {
    content: none !important;
    border-bottom: none !important;
  }
  .role-header-img {
    width: 100%;
  }
  .roles-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .heading-text {
    margin-top: 25px;
  }
  .heading-font {
    font-size: 9px !important ;
  }
  .li_text {
      margin-left: 0.5em;
      display: inline;
      font-size: 12px;
      text-align: left;
      flex: 1;
    }
  .ourRolescontent {
    font-size: 12px !important;
    line-height: 22px !important;
  }
 
  .li_numbering {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #ff3535;
  color: #ff3535;
  text-align: center;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 0.5em;
  box-sizing: content-box;
  padding: 0px;
  font-weight: bold;
  line-height: 28px;

  }
  .contactUs_mapPic {
    width: 100%;
    opacity: 1;
  }
  .our-team-header {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);
    /* height: 100px; */
  }
  .our-team-header-page {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .team-header-img {
    width: 100%;
  }
  .our-team-card {
    max-width: 200px;
    min-width: 192px;
    max-height: 400px;
  }
  .our-team-grid {
    max-height: 400px;
    max-width: 200px;
    margin-right: 25%;
    margin-left: 25%;
    margin-bottom: 20px;
    justify-content: center !important;
    /* max-width: none !important; */
  }

  .our-team-image {
    display: block;
    width: 100%;
    height: 175px;
  }
  .team-card-content {
    padding:  6px 13px !important;
    max-height: 200px;
    margin-top: 3px;
  }
  .team-social-icon {
    height: 25px;
    width: 25px;
    margin-left: 5px;
    margin-right: 10px;
  }
  /* .team-popup{
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90% !important;
     max-height: 84%;
      background-color: #fff;
      border: 2px solid #000;
      box-shadow: 24px;
      padding: 16px;
  } */
  .mobile-team-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90% !important;
    max-height: 80%;
    height: auto;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
  }
  .team-popup-image {
    height: 100px;
    width: 100px;
  }
  .team-popup-contact {
    display: flex;
    flex-direction: row;
    margin-left: -80%;
    margin-right: 10px;
  }
  .team-popup-social {
    display: flex;
    margin-left: -80%;
    margin-top: 24.5px;
  }
  .team-popup-content-font {
    font-style: normal !important;
    font-variant: normal !important;
    display: inline;
    font-size: 11px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-scroll {
    height: 250px;
  }
  .team-mobile-popup {
    display: block;
  }
  .team-laptop-popup {
    display: none;
  }
  .our-team-position{
    text-align: center;
    padding-top: 4px;
    padding-bottom: 5px;
    letter-spacing: -0.36px;
  }
  .our-team-profile-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 16px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .events-header-page {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .event-card-1{ 
    border-radius: 5px !important;
    opacity: 1;
    height: 388px;
    width: 100%;
    border: 1px  solid #D8D8D8 !important;
  }
}
/* tablet devices */
@media only screen and (min-width: 768px) {
  .container-aboutus-contactus {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .contactus-header-img {
    width: 100%;
}
.contactus-middle-container{
  margin: 30px 0px 0px ;
  }
  .contactus-middle-title{
    margin-bottom: 25px !important;
    }
    .contactus-middle-title1{
      margin-top: 5px !important;
      margin-bottom: 25px !important;
      }
      .contactus-btn-container{
        display: flex;
        justify-content: center;
      }
  .contactus-pricing-btn {
    border-radius: 10px !important;
    background: #fff !important;
    color: #ff3535 !important;
    opacity: 1;
    border-color: #ff3535 !important;
    text-transform: unset !important;
    /* width: 195px; */
    margin:24px 0px !important;
    width: 188px;
    height: 50px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .contact-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  } 
  .contact-info{
    display: flex;
    align-items: center;
    color: black;
    margin-top: 10px !important;
  }
  .formStyles {
    width: 100% !important;
    height: 50px;
    border: 1px solid #d8d8d8;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
    padding: 15px 0px 15px 17px;
  }
  .err-input {
    margin-left: 0px !important;
    border: 1px solid #ff3535 !important;
    box-shadow: 0px 2px 10px #00000029 !important;
  }
  .error-message {
    float: right;
    margin-right: 0px !important;
    font-size: 12px !important;
    color: #FF3535 !important;
}
  .MuiInputBase-root.MuiInput-root:after {
    border-bottom: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
    border-color: #d8d8d8;
  }
  .MuiInputBase-root.MuiInput-root:before {
    content: none !important;
    border-bottom: none !important;
  }
  .role-header-img {
    width: 100%;
  }
  .roles-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .heading-text {
    margin-top: 50px;
  }
  .role-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
  }
  .rolecontents {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .li_text {
    font-size: 18px;
    margin-left: 0.5em;
    display: inline;
    text-align: left;
    flex: 1
  }
  .li_numbering {
    padding: 5px;
  }

  .contactUs_mapPic {
    width: 100%;
    opacity: 1;
  }
  .our-team-header {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);
    /* height: 200px; */
  }
  .our-team-header-page {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .team-header-img {
    width: 100%;
  }
  .our-team-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .our-team-card {
    max-width: 300px;
    max-height: 500px;
  }

  .our-team-grid {
    max-height: 500px;
    max-width: 300px;
    /* margin-right: 10px;
      margin-left:  10px; */
    margin-bottom: 20px;
  }

  .our-team-image {
    display: block;
    height: 300px;
    width: 100%;
  }
  .team-card-content {
    max-height: 200px;
    margin-top: 10px;
  }
  .team-social-icon {
    height: 25px;
    width: 25px;
    margin-left: 6px;
    margin-right: 15px;
  }
  .team-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 605px;
    height: auto;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
  }

  .team-popup-image {
    height: 150px;
    width: 150px;
  }
  .team-popup-contact {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
  }
  .team-popup-social {
    display: flex;
    margin-top: 24.5px;
  }
  .team-popup-content-font {
    font-style: normal !important;
    font-variant: normal !important;
    display: inline;
    font-size: 13px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-scroll {
    height: 250px;
  }

  .team-mobile-popup {
    display: none;
  }
  .team-laptop-popup {
    display: block;
  }
  .our-team-profile-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .events-header-page {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .event-card-1{ 
    border-radius: 10px !important;
    opacity: 1;
    height: 388px;
    width: 100%;
    border: 1px  solid #D8D8D8 !important;
  }
}
/* laptop devices */
@media only screen and (min-width: 1000px) {
  .container-aboutus-contactus {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .contactus-header-img {
    width: 511px;
    height: 200px;
}
.contactus-middle-container{
  margin: 40px 0px 0px ;
  }
  .contactus-middle-title{
    margin-bottom: 35px !important;
    }
    .contactus-middle-title1{
      margin-top: 0px !important;
      margin-bottom: 28px !important;
      }
      .contactus-btn-container{
        display: flex;
        justify-content: center;
      }
  .contactus-pricing-btn {
    border-radius: 10px !important;
    background: #fff !important;
    color: #ff3535 !important;
    opacity: 1;
    border-color: #ff3535 !important;
    text-transform: unset !important;
    /* width: 195px; */
    margin-bottom:50px;
    width: 188px;
    height: 50px;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .contact-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  } 
  .contact-info{
    display: flex;
    align-items: center;
    color: black;
    margin-top: 15px !important;
  }
  .formStyles {
    width: 100% !important;
    height: 50px;
    border: 1px solid #d8d8d8;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-left: 0px !important;
    margin-bottom: 20px !important;
    padding: 15px 0px 15px 17px;
  }
  .err-input {
    margin-left: 0px !important;
    border: 1px solid #ff3535 !important;
    box-shadow: 0px 2px 10px #00000029 !important;
  }
  .error-message {
    float: right;
    margin-right: 0px !important;
    font-size: 12px !important;
    color: #FF3535 !important;
}
  .MuiInputBase-root.MuiInput-root:after {
    border-bottom: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
    border-color: #d8d8d8;
  }
  .MuiInputBase-root.MuiInput-root:before {
    content: none !important;
    border-bottom: none !important;
  }
  .role-header-img {
    width: 511px;
    height: 200px;
  }
  .roles-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .heading-text {
    margin-top: 50px;
  }
  .role-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
  }
  .rolecontents {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
    align-items: center;
  }
  .li_text {
    font-size: 18px;
    margin-left: 0.5em;
    display: inline;
    text-align: left;
    flex: 1
  }
  .li_numbering {
    padding: 5px;
  }

  .contactUs_mapPic {
    width: 90%;
    height: 396px;

    opacity: 1;
  }
  .our-team-header {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);
    /* height: 200px; */
  }
  .our-team-header-page {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .team-header-img {
    max-width: 511px;
    max-height: 200px;
  }

  .our-team-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }

  .our-team-card {
    width: 400px;
    height: 600px;
  }

  .our-team-grid {
    max-height: 600px;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    justify-content: center !important;
    max-width: none !important;
  }

  .our-team-image {
    display: block;
    height: 300px;
    width: 100%;
  }
  .team-card-content {
    max-height: 250px;
    margin-top: 10px;
  }
  .team-social-icon {
    height: 30px;
    width: 30px;
    margin-right: 15px;
  }
  .team-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: auto;
    /* height: 630px; */
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
  }
  .team-popup-image {
    height: 175px;
    width: 176px;
  }

  .team-popup-contact {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 105px;
  }
  .team-popup-social {
    display: flex;
    justify-content: start;
    margin-left: 0px;
    margin-top: 24.5px;
  }
  .team-popup-content-font {
    font-style: normal !important;
    font-variant: normal !important;
    display: inline;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-scroll {
    height: 250px;
  }
  .team-mobile-popup {
    display: none;
  }
  .team-laptop-popup {
    display: block;
  }
  .our-team-profile-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .events-header-page {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .event-card-1{ 
    border-radius: 10px !important;
    opacity: 1;
    height: 388px;
    width: 300px;
    border: 1px  solid #D8D8D8 !important;
  }
}
/* superlarge devices */
@media only screen and (min-width: 1200px) {
  .container-aboutus-contactus {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .contact-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  } 
  .contact-info{
    display: flex;
    align-items: center;
    color: black;
    margin-top: 15px !important;
  }
  .formStyles {
    width: 100% !important;
    height: 50px;
    border: 1px solid #d8d8d8;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-left: 0px !important;
    margin-bottom: 20px !important;
    padding: 15px 0px 15px 17px;
  }
  .err-input {
    margin-left: 0px !important;
    border: 1px solid #ff3535 !important;
    box-shadow: 0px 2px 10px #00000029 !important;
  }
  .error-message {
    float: right;
    margin-right: 0px !important;
    font-size: 12px !important;
    color: #FF3535 !important;
}
  .MuiInputBase-root.MuiInput-root:after {
    border-bottom: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
    border-color: #d8d8d8;
  }
  .MuiInputBase-root.MuiInput-root:before {
    content: none !important;
    border-bottom: none !important;
  }
  .role-header-img {
    max-width: 511px;
    max-height: 200px;
  }
  .roles-header-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .heading-text {
    margin-top: 50px;
  }
  .rolecontents {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1370px;
    color: #000000;
    padding-left: 15px;
    padding-right: 15px;
  }
  .li_text {
    font-size: 18px;
    margin-left: 0.5em;
    display: inline;
    text-align: left;
    flex: 1
  }
  .li_numbering {
    padding: 5px;
  }

  .contactUs_mapPic {
    width: 90%;
    height: 489px;

    opacity: 1;
  }
  .our-team-header {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);
    /* height: 200px; */
  }
  .our-team-header-page {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .team-header-img {
    max-width: 511px;
    max-height: 200px;
  }
  .our-team-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1370px;
  }

  .our-team-card {
    max-width: 400px;
    max-height: 600px;
  }

  .our-team-grid {
    max-height: 600px;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    justify-content: center !important;
    max-width: none !important;
  }
  .our-team-image {
    display: block;
    height: 400px;
    width: 100%;
  }
  .team-card-content {
    max-height: 280px;
    margin-top: 15px;
  }
  .team-social-icon {
    height: 35px;
    width: 35px;
    margin-right: 15px;
  }
  .team-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: auto;
    /* height: 630px; */
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
  }
  .team-popup-image {
    height: 175px;
    width: 176px;
  }
  .team-popup-contact {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 105px;
  }
  .team-popup-social {
    display: flex;
    justify-content: start;
    margin-left: 0px;
    margin-top: 24.5px;
  }
  .team-popup-content-font {
    font-style: normal !important;
    font-variant: normal !important;
    display: inline;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .team-popup-scroll {
    height: 250px;
  }
  .team-mobile-popup {
    display: none;
  }
  .team-laptop-popup {
    display: block;
  }
  .our-team-profile-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .events-header-page {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .event-card-1{ 
    border-radius: 10px !important;
    opacity: 1;
    height: 388px;
    width: 400px;
    border: 1px  solid #D8D8D8 !important;
  }
}
